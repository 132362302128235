define("discourse/plugins/discourse-ai/discourse/components/ai-artifact", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/html-class", "discourse-common/lib/get-url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _service, _dButton, _htmlClass, _getUrl, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiArtifactComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "expanded", [_tracking.tracked], function () {
      return false;
    }))();
    #expanded = (() => (dt7948.i(this, "expanded"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "showingArtifact", [_tracking.tracked], function () {
      return false;
    }))();
    #showingArtifact = (() => (dt7948.i(this, "showingArtifact"), void 0))();
    constructor() {
      super(...arguments);
      this.keydownHandler = this.handleKeydown.bind(this);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      window.removeEventListener("keydown", this.keydownHandler);
    }
    handleKeydown(event) {
      if (event.key === "Escape" || event.key === "Esc") {
        this.expanded = false;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleKeydown", [_object.action]))();
    get requireClickToRun() {
      if (this.showingArtifact) {
        return false;
      }
      return this.siteSettings.ai_artifact_security === "strict";
    }
    get artifactUrl() {
      return (0, _getUrl.default)(`/discourse-ai/ai-bot/artifacts/${this.args.artifactId}`);
    }
    showArtifact() {
      this.showingArtifact = true;
    }
    static #_5 = (() => dt7948.n(this.prototype, "showArtifact", [_object.action]))();
    toggleView() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        window.addEventListener("keydown", this.keydownHandler);
      } else {
        window.removeEventListener("keydown", this.keydownHandler);
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "toggleView", [_object.action]))();
    get wrapperClasses() {
      return `ai-artifact__wrapper ${this.expanded ? "ai-artifact__expanded" : ""}`;
    }
    artifactPanelHover() {
      // retrrigger animation
      const panel = document.querySelector(".ai-artifact__panel");
      panel.style.animation = "none"; // Stop the animation
      setTimeout(() => {
        panel.style.animation = ""; // Re-trigger the animation by removing the none style
      }, 0);
    }
    static #_7 = (() => dt7948.n(this.prototype, "artifactPanelHover", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.expanded}}
          {{htmlClass "ai-artifact-expanded"}}
        {{/if}}
        <div class={{this.wrapperClasses}}>
          <div
            class="ai-artifact__panel--wrapper"
            {{on "mouseleave" this.artifactPanelHover}}
          >
            <div class="ai-artifact__panel">
              <DButton
                class="btn-flat btn-icon-text"
                @icon="discourse-compress"
                @label="discourse_ai.ai_artifact.collapse_view_label"
                @action={{this.toggleView}}
              />
            </div>
          </div>
          {{#if this.requireClickToRun}}
            <div class="ai-artifact__click-to-run">
              <DButton
                class="btn btn-primary"
                @icon="play"
                @label="discourse_ai.ai_artifact.click_to_run_label"
                @action={{this.showArtifact}}
              />
            </div>
          {{else}}
            <iframe
              title="AI Artifact"
              src={{this.artifactUrl}}
              width="100%"
              frameborder="0"
              sandbox="allow-scripts allow-forms"
            ></iframe>
          {{/if}}
          {{#unless this.requireClickToRun}}
            <div class="ai-artifact__footer">
              <DButton
                class="btn-flat btn-icon-text ai-artifact__expand-button"
                @icon="discourse-expand"
                @label="discourse_ai.ai_artifact.expand_view_label"
                @action={{this.toggleView}}
              />
            </div>
          {{/unless}}
        </div>
      
    */
    {
      "id": "lfAZ/cVE",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"      \"],[1,[28,[32,0],[\"ai-artifact-expanded\"],null]],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[15,0,[30,0,[\"wrapperClasses\"]]],[12],[1,\"\\n      \"],[11,0],[24,0,\"ai-artifact__panel--wrapper\"],[4,[32,1],[\"mouseleave\",[30,0,[\"artifactPanelHover\"]]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"ai-artifact__panel\"],[12],[1,\"\\n          \"],[8,[32,2],[[24,0,\"btn-flat btn-icon-text\"]],[[\"@icon\",\"@label\",\"@action\"],[\"discourse-compress\",\"discourse_ai.ai_artifact.collapse_view_label\",[30,0,[\"toggleView\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"requireClickToRun\"]],[[[1,\"        \"],[10,0],[14,0,\"ai-artifact__click-to-run\"],[12],[1,\"\\n          \"],[8,[32,2],[[24,0,\"btn btn-primary\"]],[[\"@icon\",\"@label\",\"@action\"],[\"play\",\"discourse_ai.ai_artifact.click_to_run_label\",[30,0,[\"showArtifact\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"iframe\"],[14,\"title\",\"AI Artifact\"],[15,\"src\",[30,0,[\"artifactUrl\"]]],[14,\"width\",\"100%\"],[14,\"frameborder\",\"0\"],[14,\"sandbox\",\"allow-scripts allow-forms\"],[12],[13],[1,\"\\n\"]],[]]],[41,[51,[30,0,[\"requireClickToRun\"]]],[[[1,\"        \"],[10,0],[14,0,\"ai-artifact__footer\"],[12],[1,\"\\n          \"],[8,[32,2],[[24,0,\"btn-flat btn-icon-text ai-artifact__expand-button\"]],[[\"@icon\",\"@label\",\"@action\"],[\"discourse-expand\",\"discourse_ai.ai_artifact.expand_view_label\",[30,0,[\"toggleView\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-artifact.js",
      "scope": () => [_htmlClass.default, _modifier.on, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiArtifactComponent;
});